import styled from 'styled-components';
import homeHouse from '../../images/home-house.jpg';
export const ClearFix = styled.div`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
export const HomeCustomerCare = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  background: url(${homeHouse}) no-repeat top center;
  background-size: cover;
  overflow: hidden;
  @media (max-width: 1023px) {
    padding: 75px 50px;
  }
  @media (max-width: 767px) {
    padding: 45px 35px;
    display: block;
    background: #fff;
  }
`;

export const HomeCustomerCareGraphics = styled.div`
  flex-basis: 36%;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 100px 0 75px 100px;
  text-align: center;
  @media (max-width: 1829px) {
    flex-basis: 33%;
    padding-top: 75px;
    padding-bottom: 75px;
  }
  @media (max-width: 1299px) {
    flex-basis: 50%;
  }
  @media (max-width: 1023px) {
    flex-basis: 100%;
    padding: 0 50px 50px 50px;
  }
`;

export const HomeCustomerCareGraphicsCCE = styled.img`
  width: 300px;
  height: auto;
  margin-bottom: 55px;
  @media (max-width: 1829px) {
    width: 75%;
    height: auto;
  }
  @media (max-width: 1299px) {
    width: 310px;
    height: auto;
  }
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    max-width: 310px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HomeCustomerCareGraphicsTRUCK = styled.img`
  width: 345px;
  height: auto;
  @media (max-width: 1829px) {
    width: 75%;
    height: auto;
  }
  @media (max-width: 1299px) {
    width: 310px;
    height: auto;
  }
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    max-width: 310px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HomeCustomerCareGraphicsDriver = styled.div`
  flex-basis: 28%;
  flex-grow: 0;
  flex-shrink: 1;
  text-align: center;
  background: url(${(props) => props.$imageUrl}) no-repeat bottom center;
  background-size: 100% auto;
  @media (max-width: 1830px) {
    flex-basis: 33%;
  }
  @media (max-width: 1299px) {
    display: none;
  }
`;

export const HomeCustomerCareGraphicsText = styled.div`
  flex-basis: 36%;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 120px 100px 75px 0;
  text-align: center;

  p {
    a {
      display: inline-block;
      position: relative;
      top: 0;
      padding: 15px 35px;
      margin-right: 10px;
      font-family: 'MrAlex', sans-serif;
      font-size: 24px;
      line-height: 28px;
      color: #00a6e8;
      border: 2px solid #00a6e8;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
      border-radius: 100px;
      cursor: pointer;
      &:hover {
        top: -5px;
        color: #f7991c;
        border-color: #f7991c;
        box-shadow: 0 5px 0 0 #f7991c;
      }
    }
  }
  h2 {
    font-size: 60px;
    line-height: 60px;
    span {
      display: block;
      margin-bottom: 10px;
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
    }
    p {
      padding: 0 80px;
      font-size: 20px;
      line-height: 32px;
    }
  }

  @media (max-width: 1829px) {
    flex-basis: 33%;
    padding-top: 75px;
    padding-bottom: 75px;
    h2 {
      font-size: 47px;
      line-height: 47px;
      span {
        font-size: 38px;
        line-height: 38px;
      }
    }
    p {
      padding: 0;
    }
  }
  @media (max-width: 1439px) {
    p {
      font-size: 18px;
      line-height: 28px;
      a {
        font-size: 20px;
        line-height: 24px;
        &:hover {
          top: -5px;
          color: #f7991c;
          border-color: #f7991c;
          box-shadow: 0 5px 0 0 #f7991c;
        }
      }
    }
    h2 {
      font-size: 40px;
      line-height: 40px;
      span {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
  @media (max-width: 1229px) {
    flex-basis: 50%;
    padding-top: 120px;

    p {
      .button {
        font-size: 25px;
        line-height: 25px;
      }
      .button:before {
        top: 17px;
      }
      .button:after {
        width: 130px;
        height: 50px;
      }
    }
  }
  @media (max-width: 1023px) {
    flex-basis: 100%;
    padding: 0;

    a.button {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 767px) {
    a {
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .button {
      font-size: 22px;
      line-height: 25px;
    }

    .button:before {
      top: 17px;
    }

    .button:after {
      width: 90px;
      height: 50px;
    }
  }
`;

export const DedicatedCustomerCareHeading = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 47px;
  line-height: 47px;
  color: #00539d;
  code {
    display: block;
    font-family: 'MrAlex', sans-serif;
    font-size: 38px;
    line-height: 38px;
    text-transform: uppercase;
    color: #ffc905;
  }
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 40px;
    code {
      font-size: 30px;
      line-height: 30px;
    }
  }
`;

export const DedicatedCustomerCareParagraph = styled.p`
  padding: 0 80px;
  font-size: 20px;
  line-height: 32px;
  @media (max-width: 1439px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const DedicatedCustomerCareLinkStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;
